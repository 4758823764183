<div class="text-center hero my-5">
    <img class="mb-3 app-logo" src="../../assets/logo.png" alt="Angular logo" />
    <h1 class="mb-4">Angular Sample Project</h1>

    <p class="lead">
      This is a sample application that demonstrates an authentication flow for an
      SPA, using
      <a href="https://angular.io">Angular</a>
    </p>
  </div>

